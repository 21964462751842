.KeysHeader {
  justify-content: center;
  margin-bottom: 2rem;
}
.KeysImage {
  width: 100%;
  height: calc(100vh - 250px);
  min-height: 600px;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
}

.TwitchLink {
  color: #9146ff;
  font-weight: bold;
}

.TwitchLogo {
  height: 2rem;
  margin: 0 0.5rem;
}
