@import "../index.scss";

.App {
  display: flex;
}

.NavBrand {
  color: #bbb;
  background-color: #333;
  font-size: 3rem;
  padding: 0 2rem;

  &:hover {
    color: #fff;
  }
}

.NavRight {
  margin-left: auto;
  @media screen and (max-width: 768px) {
    display: none;
  }

  a {
    img:hover {
      opacity: 0.8;
    }
  }

  button {
    margin-right: 2rem;
  }
}

.AppContent {
  padding: 2rem 5rem;
  @media screen and (max-width: 768px) {
    padding: 2rem 2rem;
  }
}

.ant-menu-horizontal {
  border-bottom: none;
}

.ant-layout-header {
  @media screen and (max-width: 768px) {
    padding: 0;

    .ant-menu-item {
      padding: 0 1.5rem;
    }
  }
}

// .ant-menu-item > a.NavMenuItem {
//   color: $color;
// }

// .ant-menu-item-selected > a.NavMenuItem {
//   color: #ddd;
// }

.ant-table thead > tr > th,
.ant-table tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  @media screen and (max-width: 768px) {
    padding: 8px 8px;
  }
}
