@import "../index.scss";

.ITContainer {
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 600px;
}

.ITSearch {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 2rem;
  word-break: keep-all;

  div {
    margin-right: 1rem;
  }

  .ant-input {
    font-size: 16px;
  }
}

.ITContent {
  display: flex;
  flex-direction: row;
}

.ITTree {
  margin-right: 1rem;

  @media screen and (max-width: 768px) {
    display: none;
  }

  @media screen and (min-width: 768px) {
    min-width: 30%;
    width: 30%;
  }

  @media screen and (min-width: 1200px) {
    min-width: 25%;
    width: 25%;
  }
}

.ITDetail {
  flex-grow: 1;
  background: #141414;

  @media screen and (max-width: 768px) {
    * {
      td {
        img {
          max-width: 12rem;
        }
      }
    }
  }
}

.ItemCard {
  padding: 1rem;
}

.ant-table thead > tr > th {
  padding: 1rem 2rem;
}

.ItemsTableCell {
  padding: 0;
}

.ItemsTableRow {
  font-size: 1.6rem;
  cursor: pointer;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #68ee3f;
}

.TableNumber {
  white-space: nowrap;
}

.ItemTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
}

.ItemImage {
  height: 10rem;
  margin-right: 2rem;
}

.ItemName {
  font-weight: bold;
  font-size: 3rem;
  color: $color-light;

  .ItemShortName {
    opacity: 0.6;
  }
}

.ItemPrice {
  span {
    font-size: 1.7rem;
    margin-right: 0.5rem;

    &.ItemPriceNumber {
      font-weight: bold;
      color: $color-light;
      margin-right: 3rem;
    }

    &.ItemDescription {
      color: #dbdbdb;
    }
  }
}

.ItemPriceGreen {
  color: #03dac6;
}

.ItemPriceRed {
  color: #cf6679;
}

.ant-list-vertical .ant-list-item-meta {
  align-items: center;
  margin-bottom: 1rem;
}

.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 0;
}

.TradeList {
  margin-top: 1rem;
}

.TradeCard {
  display: flex;
  flex-direction: row;

  .TradeRow {
    display: flex;
    flex-direction: row;
    align-items: center;

    .TradeLink {
      display: flex;
      flex-direction: column;
      margin-right: 2rem;

      .TradeImage {
        width: 5rem;
      }

      .TradeCount {
        margin-left: 1rem;
      }
    }

    .TradeArrow {
      color: $color-light;
      margin-right: 2rem;
      font-size: 2.5rem;
    }
  }
}
