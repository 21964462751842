@font-face {
  font-family: "Bender";
  src: url("./bender.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "Bender";
  src: url("./bender-bold.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "Bender";
  src: url("./bender-light.woff") format("woff");
  font-weight: 100;
}
