html {
  font-size: 7px;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    font-size: 7px;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    font-size: 8px;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    font-size: 8px;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    font-size: 9px;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    font-size: 10px;
  }
}

body {
  font-family: "Bender", "Helvetica", "Open Sans", "Arial", sans-serif !important;
}

$color: #9a8866;
$color-dark: #604c26;
$color-light: #e8dbc3;
$color-twitch: #9146ff;
