.ant-divider-horizontal {
  margin: 1rem 0;
}

.ItemTitle {
  img {
    max-width: 30rem;

    @media screen and (max-width: 768px) {
      max-width: 20rem;
    }
  }
}
