.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #111;
  background: #9e9586;
}

.TablePrice {
  white-space: nowrap;
}

.Ammo {
  @media screen and (max-width: 768px) {
    * {
      th {
        font-size: 1.05rem;
        padding: 1rem 0 !important;
        text-align: center !important;
      }
    }

    * {
      .ant-radio-button-wrapper {
        padding: 0rem 1rem;
      }
    }
  }
}
